import React, { useContext } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx'

import { MainContext } from '~/context'
import { PulseActiveIcon } from '~/components/Icons';


const LINK_LIST = [
  { id: 1, to: '/dashboard', text: 'Your Classes' },
  { id: 2, to: '/account', text: 'Account Information' },
];

const Sidebar: React.FC<{ activeLink: string }> = ({ activeLink }) => {
  const { logout, user } = useContext(MainContext)

  const handleLogout = async () => {
    try {
      logout();
    } catch (error) {
      return console.error(error.message);
    }
  };

  return (
    <aside className="max-w-xs pt-3 w-full min-h-full bg-gray-300">
      <div className="w-full pl-7">
        <div className="pt-0 pb-10">
          <h2 className="font-bold text-gray-800 text-2xl">Your Account</h2>
          <ul className="pt-10">
            {LINK_LIST.map(link => {
              const isCurrent = link.to === activeLink
              return (
                  <li key={link.id} className={clsx('relative flex items-center py-2 text-lg border-purple-700 border-solid pr-5 md:pr-0', {
                    'font-bold text-purple-800 border-r-4': isCurrent,
                    'text-purple-800 border-r-0 pr-7': !isCurrent
                  })}>
                    <Link to={link.to}>{link.text}</Link>
                    {isCurrent && (
                      <div className="absolute right-1 lg:right-10 top-2">
                        <PulseActiveIcon />
                      </div>
                    )}
                  </li>
                )
            })}
          </ul>
        </div>
        <Link to="/" onClick={handleLogout} className="flex items-center text-purple-800 text-lg py-32">
          <i className="fad fa-sign-out text-2xl" /><span>&nbsp;Log out</span>
        </Link>
      </div>
    </aside>
  );
};
export default Sidebar;
