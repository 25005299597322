import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import clsx from "clsx";

import { MainContext } from "~/context";

import Button from "~/components/button";
import { PulseIcon } from "~/components/Icons";

import styles from "./styles.module.css";


interface HeaderProps {
  color?: string;
  islogin?: boolean;
  isDashboard: boolean;
}

export const Header = ({
  color = "ms",
  islogin,
  isDashboard,
}: HeaderProps) => {
  const [accountPage, setAccountPage] = useState<boolean>(false);
  const [signInPage, setSignInPage] = useState<boolean>(false);

  const { user } = useContext(MainContext);

  const isMs: boolean = color === "ms";
  const isLoggedUser: boolean = typeof islogin !== "undefined" ? islogin : !!user;

  useEffect(() => {
    const pathname: string[] | false = typeof window !== "undefined" && window.location.pathname.split("/");

    if (pathname && pathname.length > 1) {
      const page: string = pathname[1];

      if (page === "sign-up" || page === "sign-in") {
        setSignInPage(true);
        return;
      }

      if (page === "account") {
        setAccountPage(true);
        return;
      }
    }
  }, []);

  return (
    <header className={`flex items-center justify-between flex-col lg:flex-row ${styles.Header}`}>
      <div className={`flex lg:items-center justify-start flex-col lg:flex-row mb-6 lg:mb-0 ${styles.HeaderIcon}`} color={color}>
        <PulseIcon className={clsx({
          "text-purple-700": isMs,
          "text-indigo-700": !isMs
        })}/>
        <h1 className="font-bold text-darkBlue text-lg leading-8 pl-6 lg:pl-0 ml-4 -mt-8 lg:mt-0">Making Evidence-Driven Decisions in a Media-Driven World</h1>
      </div>
      <div>
        <nav className="flex items-center">
          {!isLoggedUser && !signInPage && (
            <Link to="/sign-in">
              <Button action="tertiary" color="purple" title="Register">
                Register
              </Button>
            </Link>
          )}
          {isLoggedUser && !accountPage && (
            <Link to="/account">
              <Button action="tertiary" color="purple" title="Account">
                <span className="flex items-center"><i className="fas fa-user" /><span className="ml-2">Account</span></span>
              </Button>
            </Link>
          )}
          <Link
            className="ml-5"
            to={isDashboard || signInPage
              ? "/"
              : isLoggedUser ? "/dashboard" : "/sign-in"
            }
          >
            <Button
              color={isMs ? "purple" : "indigo"}
              action="primary"
              title={isDashboard || signInPage
                ? "Join class"
                : isLoggedUser ? "Teacher Zone" : "Sign in"
              }
            >
              {isDashboard || signInPage
                ? "Join class"
                : isLoggedUser ? "Teacher Zone" : "Sign in"
              }
            </Button>
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
