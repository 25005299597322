import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "gatsby";

import { MainContext } from "~/context";

import { put } from "~/helpers/api";

import useAuth from "~/hooks/useAuth";

import AccountLayout from "~/layouts/account";

import Button from "~/components/button";
import ClassInformation, { ClassType } from "~/components/class-information";
import { DeleteClassModal, UpdateClassModal, MultipleModalState } from "~/components/modals"
import { Heading } from "~/components/Typography";


const AccountClasses = (): JSX.Element => {
  const authenticated: boolean = useAuth("/sign-in");

  const [current, setCurrent] = useState<number>(-1);
  const [id, setID] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [multipleUpdateModalState, setMultipleUpdateModalState] = useState<MultipleModalState>({ current: -1, previous: -1 });
  const [multipleDeleteModalState, setMultipleDeleteModalState] = useState<MultipleModalState>({ current: -1, previous: -1 });
  const [loading, setLoading] = useState(false);

  const { user, getUser, deleteClass } = useContext(MainContext);
  const [classes, setClasses] = useState<ClassType[]>(user?.classes.data ?? []);

  const handleDelete = (id: string) => {
    return async () => {
      setLoading(true);

      try {
        await deleteClass(id);
        await getUser();

        setLoading(false);
        setMultipleDeleteModalState({ current: -1, previous: current});
      } catch (error) {
        setLoading(false);
        setMultipleDeleteModalState({ current: -1, previous: current});
      }
    };
  };

  const handleUpdate = (id: string, name: string) => {
    return async () => {
      setLoading(true);
      // @ts-ignore
      const { updateClassName } = await put('/updateClassName', { id, name });

      if (!updateClassName) {
        setLoading(false);
        setError("There was an error updating your class name. Please try again later.");
        setMultipleUpdateModalState({ current: -1, previous: current});
        return;
      }

      try {
        const updatedClass: ClassType | undefined = classes.find(c => c._id === id);

        if (updatedClass) {
          updatedClass.name = name;

          // React doesn't re-render if classes is mutated and setClasses is called with the mutated array
          // The array reference must change so I'm spreading the original array into a new array reference.
          setClasses([...classes]);
        };

        setError("");
        setLoading(false);
        setMultipleUpdateModalState({ current: -1, previous: current});
      } catch (error) {
        setLoading(false);
        setError("There was an error updating your class name. Please try again later.");
        setMultipleUpdateModalState({ current: -1, previous: current});
      }
    };
  };

  const deleteHandler = useCallback(() => {
    handleDelete(id)();
  }, [id]);

  const updateHandler = useCallback((name: string) => {
    handleUpdate(id, name)();
  }, [id]);

  useEffect(() => {
    if (error) alert(error);
  }, [error]);

  if (!authenticated) {
    return <div>...loading</div>;
  }

  return (
    <AccountLayout activeLink="/dashboard">
      <div className="flex flex-wrap md:flex-nowrap w-full items-center justify-between">
        <Heading color="ms" level={1} underline>
          You have <span className="text-purple-600">{classes?.length}</span> classes
        </Heading>
        <Link to="/class" className="mt-5 md:mt-0">
          <Button color="purple" action="secondary" title="Add new class">
            Add new class
          </Button>
        </Link>
      </div>
      <div className="w-2/3 pt-8">
        {classes?.map((classInformation: ClassType, index: number) => (
          <ClassInformation
            index={index}
            item={classInformation}
            key={classInformation._id}
            handleDelete={(id: string) => {
              setCurrent(index);
              setMultipleDeleteModalState((state: MultipleModalState) => ({ current: index, previous: state.current }));
              setID(id);
            }}
            handleUpdate={(id: string) => {
              setCurrent(index);
              setMultipleUpdateModalState((state: MultipleModalState) => ({ current: index, previous: state.current }));
              setID(id);
            }}
            multipleDeleteModalState={multipleDeleteModalState}
            multipleUpdateModalState={multipleUpdateModalState}
          />
        ))}
      </div>
      {multipleDeleteModalState.current >= 0 && (
        <div className="fixed inset-0 w-full h-screen">
          <DeleteClassModal
            toggleVisibility={() => setMultipleDeleteModalState({ current: -1, previous: current })}
            onClick={() => deleteHandler()}
            loading={loading}
          />
        </div>
      )}
      {multipleUpdateModalState.current >= 0 && (
        <div className="fixed inset-0 w-full h-screen">
          <UpdateClassModal
            toggleVisibility={() => setMultipleUpdateModalState({ current: -1, previous: current })}
            onClick={(name: string) => updateHandler(name)}
            loading={loading}
          />
        </div>
      )}
    </AccountLayout>
  );
};

export default AccountClasses;
