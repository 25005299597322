import React from 'react';

import AppWrapper from './app-wrapper';
import Header from '~/components/header';
import Footer from '~/components/footer';
import Sidebar from '~/components/Sidebar';

const AccountLayout: React.FC<{ activeLink: string }> = ({
  children,
  activeLink,
}) => {
  return (
    <AppWrapper>
      <Header isDashboard/>
      <main className="flex w-full h-full" style={{minHeight: 'calc(100vh - 80px - 96px)'}}>
        <Sidebar activeLink={activeLink} />
        <div className="p-9 pr-25 w-full min-h-full bg-lessonBackground">{children}</div>
      </main>
      <Footer isLarge={false} />
    </AppWrapper>
  );
};

export default AccountLayout;
