import React, { RefObject, useEffect, useRef, useState  } from "react";
import { Link } from "gatsby";
import clsx from "clsx";

import Tip from "~/components/tip";
import { MultipleModalState } from "~/components/modals";

export interface ClassType {
  code: string;
  level: string;
  name: string;
  _id: string;
}

interface ClassInformationProps {
  handleDelete: (id: string) => void;
  handleUpdate: (id: string) => void;
  index: number;
  item: ClassType;
  multipleDeleteModalState: MultipleModalState;
  multipleUpdateModalState: MultipleModalState;
}


const ClassInformation = ({ index, item, handleDelete, handleUpdate, multipleDeleteModalState, multipleUpdateModalState }: ClassInformationProps): JSX.Element => {
  const [tipVisibility, setTipVisibility] = useState<boolean>(false);

  const codeRef: RefObject<HTMLSpanElement> = useRef<HTMLSpanElement>(null);
  const deleteClassRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const updateClassRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);

  const handleCopy = (): void => {
    const copyBoxElement: HTMLSpanElement | null = codeRef.current;

    if (!copyBoxElement) return;

    copyBoxElement.contentEditable = "true";
    copyBoxElement.focus();
    document.execCommand("selectAll");
    document.execCommand("copy");
    copyBoxElement.contentEditable = "false";

    getSelection()?.empty();
    setTipVisibility(true);
  };

  const level: string = item.level === "HS" ? "high-school" : "middle-school";
  const courseURL: string = `/${level}/lesson-1/step-1/notes`;

  const correctLevel: string | false =
    (item.level === "HS" && "High School") ||
    (item.level === "MS" && "Middle School");

  useEffect(() => {
    if (multipleDeleteModalState.previous !== index || !deleteClassRef?.current) return;

    deleteClassRef.current.focus();
  }, [index, multipleDeleteModalState.previous, multipleDeleteModalState.current]);

  useEffect(() => {
    if (multipleUpdateModalState.previous !== index || !updateClassRef?.current) return;

    updateClassRef.current.focus();
  }, [index, multipleUpdateModalState.previous, multipleUpdateModalState.current]);

  useEffect(() => {
    if (tipVisibility) {
      setTimeout(() => {
        setTipVisibility(false);
      }, 3000);
    }
  }, [tipVisibility]);

  return (
    <div className="flex flex-wrap py-4 border-b border-b-solid border-b-gray-300 my-3">
      <div className="flex w-full items-center pb-5">
        <Link to={courseURL}>
          <h2 className="mt-0 text-gray-600 text-base font-bold cursor-pointer">
            <span>{item.name}</span>
          </h2>
        </Link>
        <div className="flex space-x-2  ml-4 lg:ml-auto">
          <button
            className="focus:ring-2 focus:ring-gray-300 rounded outline-none"
            onClick={() => handleUpdate(item._id)}
            ref={updateClassRef}
            title="Edit class name."
            type="button"
          >
            <i className="far fa-pencil-alt text-lg text-gray-700" />
          </button>
          <button
            className="focus:ring-2 focus:ring-red-600 rounded outline-none"
            onClick={() => handleDelete(item._id)}
            ref={deleteClassRef}
            title="remove"
            type="button"
          >
            <i className="far fa-trash-alt text-lg text-red-700" />
          </button>
        </div>
      </div>
      <div className="flex flex-wrap md:flex-nowrap w-full">
        <p
          className={clsx(
            "text-gray-500 text-sm flex items-center before:block before:rounded-full before:w-3 before:mr-4 before:h-3",
            {
               "before:bg-purple-600": correctLevel === "Middle School",
               "before:bg-indigo-600": correctLevel === "High School",
            }
          )}
        >
          {correctLevel}
        </p>
        <div className="ml-auto mt-2 md:mt-0">
          <div className="relative">
            <p className="text-sm flex items-center">
              <span className="text-gray-500">Class code:&nbsp;</span>
              <button
                className={clsx(
                  "relative w-min focus:ring-2 outline-none rounded",
                  {
                    "focus:ring-purple-600": correctLevel === "Middle School",
                    "focus:ring-indigo-600": correctLevel === "High School"
                  }
                )}
                onClick={handleCopy}
              >
                <span
                  className={clsx(
                    "flex flex-nowrap items-center cursor-pointer",
                    {
                      "text-purple-600": correctLevel === "Middle School",
                      "text-indigo-600": correctLevel === "High School"
                    }
                  )}
                  ref={codeRef}
                >
                  {item.code}&nbsp;<i className="far fa-clone" />
                </span>
                <Tip visible={tipVisibility} title="Copied"/>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassInformation;
