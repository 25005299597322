import clsx from "clsx";
import React from "react";
import styles from "./styles.module.css";

interface TipProps {
  visible: boolean;
  title: string;
}

const Tip = ({ visible = true, title = "Copied!" }: TipProps): JSX.Element => (
  <div
    title={title}
    className={clsx(
      `
        after:absolute
        after:content-center
        after:flex
        after:text-white
        before:absolute
        before:border-solid
        duration-300
        opacity-0
        text-white
        transition-opacity
        visible
        ${styles.Element}
      `,
       {
         "opacity-100": visible
       }
    )}
  />
);

export default Tip;
